<template>
  <v-data-table
      :headers="headers"
      :items="categories"
      sort-by="calories"
      class="elevation-1"
  >
    <template v-slot:body>
      <draggable v-model="sortedCategories" @start="drag=true" @end="drag=false" tag="tbody">
        <tr v-for="(item, index) in sortedCategories" :key="index">
          <td>{{item.name}}</td>
          <td>{{item.courses_count}}</td>
          <td>
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </draggable>
    </template>
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Categories</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="blue">mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.name"
                        label="Category name"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios"
import draggable from "vuedraggable"

export default {
  components: {draggable},
  data: () => ({
    drag: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Total courses',
        value: 'courses_count'
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    categories: [],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
    sortedCategories:[]
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      }
      formData.append('name', this.editedItem.name)
      return formData
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    categories(){
      this.sortedCategories = this.categories
    },
    sortedCategories(newVal, oldVal){
      if (oldVal.length) {
        this.sortItems()
      }
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    sortItems() {
      const url = 'admin/categories'
      let data = {
        categories: this.sortedCategories,
        type: 'sort'
      }
      axios.post(url, data)
    },
    initialize() {
      const url = 'admin/categories'
      axios.get(url).then((response) => {
        this.categories = response.data
        this.sortedCategories = response.data
      }).catch(() => {

      })
    },

    editItem(item) {
      this.editedIndex = this.categories.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.categories.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const url = 'admin/categories/' + this.editedItem.id
      let editedIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.categories.splice(editedIndex, 1)
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/categories/' + this.editedItem.id
        let editedIndex = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.categories[editedIndex], response.data)
        })
      } else {
        const url = 'admin/categories'
        axios.post(url, this.form).then((response) => {
          this.categories.push(response.data)
        })
      }
      this.close()
    },
  },
}
</script>
